import React, { useRef } from 'react';
import Lottie from "lottie-react";

const ResetPassword = ({ redirectLink, resetPasswordloopImg }) => {
  const animation = useRef(null);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#303032',
        textAlign: 'center',
        backgroundColor: 'rgb(194, 216, 76)',
        padding: '10% 5%',
        minHeight: '100vh', // Ensure it takes full height
      }}
    >
      <div
        style={{
          fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
          width: '100%',
        }}
      >
        <h1
          className="title"
          style={{
            margin: 'auto',
            textAlign: 'center',
            fontFamily: 'beezle',
            fontSize: '2rem', // Adjusted for responsiveness
            padding: '0 10px',
          }}
        >
          PASSWORD RESET FOR NEW DEW
        </h1>
      </div>

      <div
        className="msgBody"
        style={{
          fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
          backgroundColor: '#efede7',
          alignContent: 'normal',
          width: '100%',
          maxWidth: '900px', // Maximum width to ensure content doesn't stretch too much
          padding: '5% 8%',
          borderRadius: '9px',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <Lottie
            animationData={require("../../assets/eve1.json")}
            style={{
              width: '40vw', // Responsive width based on viewport width
              height: '40vw', // Maintain aspect ratio
              marginBottom: '20px',
              maxWidth: '300px', // Max width for large screens
            }}
            autoPlay
          />
          <h1 className="titleHead" style={{ textAlign: 'center', fontSize: '1.5rem' }}>
            Reset Password
          </h1>
        </div>

        <div
          style={{
            backgroundColor: '#ddd',
            height: 'auto',
            padding: '5% 10%',
            borderRadius: '9px',
            boxSizing: 'border-box',
            marginBottom: '10px',
          }}
        >
          <p style={{ fontSize: '1rem', marginBottom: '10px' }}>
            Please follow this link to{' '}
            <a href={redirectLink} style={{ textDecoration: 'none', color: 'inherit' }}>
              <button
                className="btn"
                style={{
                  border: '2px solid black',
                  backgroundColor: 'white',
                  color: 'black',
                  padding: '12px 28px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  margin: '10px 0',
                }}
              >
                Reset
              </button>
            </a>{' '}
            your password.
          </p>
          <small style={{ fontSize: '0.875rem' }}>
            This link expires in 60 minutes.
          </small>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;


